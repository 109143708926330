import React from 'react';
import { ThemesEnum } from '@uc-common/theme';

import {
  IntroScreen,
  UploadApiScreen,
  PlatformApiScreen,
  CDNApiScreen,
} from '../../components/forAPI';
import { SmallCTA } from '../../components/SmallCTA';
import { CommonLayout } from '../../components/Layout/CommonLayout';
import { frontmatter as originalFrontmatter } from '../api';
import { Navbar, NavbarModesEnum } from '../../components/Navbar';

export const frontmatter = {
  canonicalUrl: 'https://uploadcare.com/api/',
  noindex: true,
};

const API = ({ location }) => (
  <CommonLayout
    meta={{
      ...originalFrontmatter,
      ...frontmatter,
    }}
    pathName={location.pathname}
    isDark
  >
    <Navbar mode={NavbarModesEnum.PPC} initialTheme={ThemesEnum.DARK} />
    <IntroScreen ppc />
    <UploadApiScreen ppc />
    <PlatformApiScreen ppc />
    <CDNApiScreen ppc />
    <SmallCTA ppc btnSignUpProps={{ 'data-analytics': 'btn_signup_4' }} />
  </CommonLayout>
);

export default API;
